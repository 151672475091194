import React, { useEffect, useState } from "react";
import { Box, Button, IconButton, Typography, Hidden } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";
import AddIcon from "@mui/icons-material/Add";

import { setIsNewChatModel } from "../redux/reducer";
import StartNewChat from "./Main/StartNewChat";
import Sidebar from "../Components/Sidebar";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [drawer, setDrawer] = useState(false);

  const isTyping = useSelector((state) => state.isTyping);
  const chatHistory = useSelector((state) => state.chatHistory);
  const typeOfAg = useSelector((state) => state.typeOfAg);
  const agentList = useSelector((state) => state.agentList);

  const handleClickOpen = () => {
    dispatch(setIsNewChatModel(true));
  };

  return (
    <Box
      sx={{
        padding: "15px",
        backgroundColor: "rgba(255,255,255,0.9)",
        position: "absolute",
        width: "-webkit-fill-available",
        zIndex: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            cursor: "pointer",
          }}
          onClick={() => navigate("/dashboard", { state: { tabValue: 0 } })}
        >
          <Typography sx={{ fontSize: "24px !important", color: "#1235f5" }}>
            {["UX", "meetingAssistant", "default"].includes(typeOfAg)
              ? "praxis/ai"
              : agentList.find((it) => it.agentName === typeOfAg)?.agentLabel}
          </Typography>
        </Box>
        {typeOfAg === "UX" && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              border: "2px solid #193bf5",
              padding: { md: "6px 12px", xs: "4px 10px" },
              borderRadius: 4,
            }}
          >
            <Typography
              sx={{
                color: (theme) => theme.palette.grey.gray_500,
                fontSize: { md: "14px", xs: "12px" },
                fontWeight: 700,
              }}
            >
              UX Assistant
            </Typography>
          </Box>
        )}

        <Box sx={{ display: "flex", gap: 2 }}>
          {!isTyping && chatHistory.length !== 0 && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                variant="text"
                sx={{
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: { md: "14px", xs: "10px" },
                  fontWeight: 500,
                  textTransform: "capitalize",
                  minWidth: { xs: "0px" },
                }}
                endIcon={<AddIcon />}
                onClick={handleClickOpen}
              >
                <Hidden only={["sm", "xs"]}>New Chat</Hidden>
              </Button>
            </Box>
          )}

          <Box
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.04)",
              borderRadius: "50px",
            }}
          >
            <IconButton onClick={() => setDrawer(true)}>
              <MenuIcon />
            </IconButton>
          </Box>
        </Box>

        <Sidebar
          setDrawer={setDrawer}
          handleClickOpen={handleClickOpen}
          drawer={drawer}
        />
      </Box>
      <Box>
        <StartNewChat fnCall={() => setDrawer(false)} />
      </Box>
    </Box>
  );
};

export default Header;
