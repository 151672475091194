import React, { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  FormControlLabel,
  Slider,
  MenuItem,
  Select,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Drawer,
} from "@mui/material";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import AddIcon from "@mui/icons-material/Add";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import {
  setTypeOfAg,
  setIsRFormate,
  setSelectedAIModel,
  setTemperature,
  setVectorTypeDB,
  setPipelineTypeDoc,
  setFlowPipeline,
} from "../redux/reducer";
import IOSSwitch from "./IOSSwitch";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
    marginBottom: 10,
  },
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "&.MuiToggleButtonGroup-root": {
    backgroundColor: "rgba(236,236,241,1)",
    borderRadius: 10,
    padding: 5,
  },
  "& .MuiToggleButtonGroup-grouped": {
    borderRadius: 10,
    borderColor: "rgba(207,207,214,0.4)",
    textTransform: "capitalize !important",
  },
  "& .Mui-selected": {
    backgroundColor: "white !important",
  },
}));

function Sidebar({ setDrawer, handleClickOpen, drawer }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const pipelineTypeDoc = useSelector((state) => state.pipelineTypeDoc);
  const flowPipeline = useSelector((state) => state.flowPipeline);
  const myFileList = useSelector((state) => state.myFileList);
  const selectedAIModel = useSelector((state) => state.selectedAIModel);
  const vectorTypeDB = useSelector((state) => state.vectorTypeDB);
  const isRFormate = useSelector((state) => state.isRFormate);
  const temperature = useSelector((state) => state.temperature);
  const typeOfAg = useSelector((state) => state.typeOfAg);
  const agentList = useSelector((state) => state.agentList);

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      navigate("/signin");
    } catch (error) {
      console.log("Error signing out:", error);
    }
  };

  return (
    <Drawer
      open={drawer}
      onClose={() => setDrawer(false)}
      role="presentation"
      anchor="right"
    >
      <Box
        p="6px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px solid rgba(0, 0, 0, 0.12)"
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 500,
            paddingLeft: "16px",
          }}
        >
          Menu
        </Typography>
        <IconButton onClick={() => setDrawer(false)}>
          <CloseSharpIcon />
        </IconButton>
      </Box>
      <Box className={classes.root}>
        <Box sx={{ paddingLeft: "16px" }}>
          <Button
            variant="text"
            sx={{
              color: "rgba(0, 0, 0, 0.87)",
              fontSize: "14px",
              fontWeight: 500,
              textTransform: "capitalize",
            }}
            endIcon={<AddIcon />}
            onClick={handleClickOpen}
          >
            New Chat
          </Button>
          <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
            <Typography fontWeight="500">AI model:</Typography>

            <Box sx={{ ml: 4 }}>
              <Select
                value={selectedAIModel}
                fullWidth
                onChange={(e) => dispatch(setSelectedAIModel(e.target.value))}
              >
                <MenuItem
                  value="gpt-3.5-turbo-0125"
                  disabled={pipelineTypeDoc === "openaiFile"}
                >
                  GPT-3.5
                </MenuItem>
                <MenuItem value="gpt-4-turbo">GPT-4 Turbo</MenuItem>
                <MenuItem value="gpt-4o">GPT-4o</MenuItem>
                <MenuItem value="gpt-4o-mini">gpt-4o-mini</MenuItem>
              </Select>
            </Box>
          </Box>
          <Box sx={{ mt: 4, display: "flex", alignItems: "center" }}>
            <Typography fontWeight="500">Agent:</Typography>

            <Box sx={{ ml: 6 }}>
              <Select
                value={typeOfAg}
                onChange={(e) => dispatch(setTypeOfAg(e.target.value))}
                fullWidth
              >
                <MenuItem value={"default"}>Standard</MenuItem>
                <MenuItem value={"meetingAssistant"}>
                  Meeting Assistant
                </MenuItem>
                <MenuItem value={"UX"}>UX Assistant</MenuItem>
                {agentList.map((item) => (
                  <MenuItem value={item.agentName}>{item.agentName}</MenuItem>
                ))}
              </Select>
            </Box>
          </Box>

          <Box sx={{ mt: 4 }}>
            <Accordion>
              <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
                <Typography>Admin</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    mt: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{ textTransform: "capitalize" }}
                    onClick={() => {
                      setDrawer(false);
                      navigate("/manegeAgent", { state: { isAddAgent: true } });
                    }}
                  >
                    Add Agent
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ textTransform: "capitalize" }}
                    onClick={() => {
                      setDrawer(false);
                      navigate("/manegeAgent");
                    }}
                  >
                    Mange Agent
                  </Button>
                </Box>
                <Box sx={{ mt: 4, display: "flex", alignItems: "center" }}>
                  <Typography fontWeight="500">Doc Pipeline Type:</Typography>

                  <Box sx={{ ml: 6 }}>
                    <Select
                      value={pipelineTypeDoc}
                      onChange={(e) => {
                        if (myFileList >= 1) {
                          handleClickOpen();
                        }
                        if (e.target.value === "codeInterpreter") {
                          dispatch(setSelectedAIModel("gpt-4-turbo"));
                        }
                        dispatch(setPipelineTypeDoc(e.target.value));
                      }}
                      fullWidth
                    >
                      <MenuItem value={"openaiFile"}>
                        OpenAI file assistant
                      </MenuItem>
                      <MenuItem value={"codeInterpreter"}>
                        OpenAI code interpreter
                      </MenuItem>
                      <MenuItem value={"langchain"}>
                        Langchain vector chain
                      </MenuItem>
                    </Select>
                  </Box>
                </Box>

                <Box sx={{ mt: 4, display: "flex", alignItems: "center" }}>
                  <Typography fontWeight="500">Work Flow Type:</Typography>
                  <Box sx={{ ml: 6 }}>
                    <Select
                      value={flowPipeline}
                      onChange={(e) => {
                        dispatch(setFlowPipeline(e.target.value));
                      }}
                      fullWidth
                    >
                      <MenuItem value={"langGraph"}>langGraph</MenuItem>
                      <MenuItem value={"funchain"}>func-Chain</MenuItem>
                    </Select>
                  </Box>
                </Box>

                <Box sx={{ mt: 4 }}>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        sx={{ ml: 2 }}
                        checked={isRFormate}
                        onChange={(e) =>
                          dispatch(setIsRFormate(e.target.checked))
                        }
                      />
                    }
                    label="Report format"
                    labelPlacement="start"
                    sx={{ ml: 0 }}
                  />
                </Box>

                {pipelineTypeDoc === "langchain" && (
                  <Box sx={{ mt: 4, display: "flex", alignItems: "center" }}>
                    <Typography fontWeight="500" mr={1}>
                      Select vector store:-
                    </Typography>
                    <StyledToggleButtonGroup
                      color="primary"
                      value={vectorTypeDB}
                      exclusive
                      onChange={(e) =>
                        dispatch(setVectorTypeDB(e.target.value))
                      }
                    >
                      <ToggleButton value="FAISS">FAISS (faster)</ToggleButton>
                      <ToggleButton value="chromadb">
                        Chroma (longer)
                      </ToggleButton>
                      {/* { (
                          <ToggleButton value="elasticSearch">
                            Elastic-Search
                          </ToggleButton>
                        )} */}
                    </StyledToggleButtonGroup>
                  </Box>
                )}

                <Box sx={{ mt: 4, display: "flex", alignItems: "center" }}>
                  <Typography fontWeight="500" mr={1}>
                    Temperature:-
                  </Typography>
                  <Slider
                    value={temperature}
                    step={0.1}
                    min={0}
                    max={1}
                    valueLabelDisplay="auto"
                    onChange={(e, newvalue) =>
                      dispatch(setTemperature(newvalue))
                    }
                    sx={{ mr: 3 }}
                  />
                </Box>

                <Box sx={{ mt: 4 }}>
                  <Button
                    variant="outlined"
                    sx={{ textTransform: "capitalize" }}
                    onClick={() => {
                      setDrawer(false);
                      navigate("/Categorization");
                    }}
                  >
                    Categorisation Agent
                  </Button>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>

        <Box>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
              borderTop: "1px solid rgba(0, 0, 0, 0.12)",
              cursor: "pointer",
            }}
            onClick={() => {
              handleLogout();
            }}
          >
            Log out
            <LogoutIcon sx={{ ml: 1 }} />
          </Typography>
        </Box>
      </Box>
    </Drawer>
  );
}

export default Sidebar;
