import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Formik, Form, ErrorMessage } from "formik";
import * as yup from "yup";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import IOSSwitch from "../Components/IOSSwitch";
import FormField from "../assets/theme/overrides/FormField";
import MUIDataTable from "mui-datatables";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingButton from "@mui/lab/LoadingButton";
import { useConfirm } from "material-ui-confirm";
import { setAgentList } from "../redux/reducer";

const defaultColumns = [
  {
    name: "id",
    label: "id",
    options: {
      display: false,
      filter: false,
    },
  },
  {
    name: "agentName",
    label: "Agent Name",
  },
  {
    name: "systemPrompt",
    label: "System prompt",
  },
  {
    name: "isWebTool",
    label: "Web search tool",
  },
  {
    name: "userId",
    label: "User Id",
  },
];

function AddAgent({ onSuccess, editData }) {
  const confirm = useConfirm();
  const dispatch = useDispatch();

  const [editFileArr, setEditFileArr] = useState([]);
  const [delFileIds, setDelFileIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector((state) => state.user);
  const isEdit = Boolean(editData);

  useEffect(() => {
    if (editData?.fileIds) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/agent/getFiles`, {
          fileIds: editData?.fileIds,
        })
        .then(({ data }) => {
          setEditFileArr(data);
        });
    }
  }, [editData]);

  const handleSubmit = async (data) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("agentName", data?.agentName);
      formData.append("systemPrompt", data?.systemPrompt);
      formData.append("isWebTool", data?.isWebTool);
      formData.append("agentLabel", data?.agentLabel);
      formData.append("agentText", data?.agentText);
      formData.append("userId", user.sub);
      formData.append("delFileIds", JSON.stringify(delFileIds));

      for (let i = 0; i < data?.files.length; i++) {
        formData.append("files", data?.files[i]);
      }
      if (isEdit) formData.append("id", editData.id);

      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/${
          editData ? "updateAgent" : "addAgent"
        }`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      axios
        .get(`${process.env.REACT_APP_API_URL}/api/agentList/${user.sub}`)
        .then(({ data }) => {
          dispatch(setAgentList(data));
        });

      setIsLoading(false);
      toast.success("Agent added successfully");
      onSuccess();
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <Formik
      initialValues={{
        agentName: editData?.agentName || "",
        systemPrompt: editData?.systemPrompt || "",
        agentLabel: editData?.agentLabel || "",
        agentText: editData?.agentText || "",
        isWebTool: editData?.isWebTool === "false" ? false : true,
        files: [],
      }}
      validationSchema={yup.object().shape({
        agentName: yup.string().required("Agent tName is required"),
        systemPrompt: yup.string().required("System Prompt is required"),
        agentLabel: yup.string().required("Agent Label is required"),
        agentText: yup.string().required("Agent Text is required"),
        isWebTool: yup.boolean().required("Web tool is required"),
        files: yup.mixed(),
      })}
      onSubmit={handleSubmit}
      name="sendcode"
    >
      {({ values, setFieldValue, handleBlur }) => (
        <Form>
          <Box>
            <FormField label="Agent Name" name="agentName" type="text" />
          </Box>
          <Box sx={{ mt: 3 }}>
            <FormField
              multiline
              minRows={5}
              label="System Prompt"
              name="systemPrompt"
              type="text"
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <FormField label="Agent Label" name="agentLabel" type="text" />
          </Box>
          <Box sx={{ mt: 2 }}>
            <FormField label="Agent Text" name="agentText" type="text" />
          </Box>
          <Box sx={{ mt: 3, display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "100%" }}>
              <TextField
                type="file"
                onChange={(e) => setFieldValue("files", e.target.files)}
                fullWidth
                inputProps={{ multiple: "multiple" }}
                disabled={isEdit}
              />
              {editFileArr.map((item) => (
                <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                  <Typography>{item.filename}</Typography>
                  <DeleteIcon
                    sx={{ ml: 2 }}
                    onClick={() =>
                      confirm({
                        description: "This action cannot be undo",
                      }).then(() => {
                        setDelFileIds([...delFileIds, item.fileId]);
                        setEditFileArr(
                          editFileArr.filter((it) => it.fileId != item.fileId)
                        );
                      })
                    }
                  />
                </Box>
              ))}
            </Box>
          </Box>

          <Box sx={{ mt: 4 }}>
            <FormControlLabel
              control={
                <IOSSwitch
                  sx={{ ml: 2 }}
                  onChange={(e) => setFieldValue("isWebTool", e.target.checked)}
                  checked={values.isWebTool}
                  onBlur={handleBlur}
                  name="isWebTool"
                />
              }
              label="Web search tool"
              labelPlacement="start"
              sx={{ ml: 0 }}
            />
            <ErrorMessage name="isWebTool" component="div" />
          </Box>

          <Box sx={{ mt: 4, justifyContent: "center", display: "flex" }}>
            <LoadingButton
              variant="contained"
              size="large"
              style={{ width: "30%" }}
              type="submit"
              disabled={isLoading}
              loading={isLoading}
            >
              {isEdit ? "Save Agent" : "Add Agent"}
            </LoadingButton>
          </Box>
        </Form>
      )}
    </Formik>
  );
}

const ManageAgent = () => {
  const [agentList, setAgentList] = useState([]);
  const [editData, setEditData] = useState(false);
  const { state } = useLocation();
  const [isAddAgent, setIsAddAgent] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (state?.isAddAgent) setIsAddAgent(state?.isAddAgent);
  }, [state]);

  const user = useSelector((state) => state.user);

  const columns = [
    ...defaultColumns,
    {
      label: "Actions",
      name: "Add",
      options: {
        filter: false,
        sort: false,
        empty: true,

        customBodyRender: (_, tableMeta) => {
          return (
            <>
              <EditIcon
                onClick={() => {
                  setIsAddAgent(true);
                  setEditData(
                    agentList.find((item) => item.id === tableMeta.rowData[0])
                  );
                }}
              />
              <DeleteIcon
                onClick={() =>
                  axios
                    .post(`${process.env.REACT_APP_API_URL}/api/deleteAgent`, {
                      id: tableMeta.rowData[0],
                    })
                    .then(() => {
                      fetchData();
                    })
                }
                sx={{ ml: 3 }}
              />
            </>
          );
        },
      },
    },
  ];

  const fetchData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/agentList/${user.sub}`)
      .then(({ data }) => {
        setAgentList(data);
      });
  };

  useEffect(() => {
    if (user?.sub) fetchData();
  }, [user]);

  const onBack = () => {
    if (isAddAgent) {
      setIsAddAgent(false);
    } else {
      navigate("/dashboard");
    }
  };

  return (
    <Box
      sx={{
        m: 10,
        ml: 5,
      }}
    >
      <ArrowBackIcon onClick={onBack} />
      {isAddAgent ? (
        <AddAgent
          onSuccess={() => {
            setIsAddAgent(false);
            fetchData();
          }}
          editData={editData}
        />
      ) : (
        <Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              onClick={() => {
                setIsAddAgent(true);
                setEditData(false);
              }}
              sx={{ mb: 5 }}
              size="large"
            >
              Add agent
            </Button>
          </Box>
          <MUIDataTable
            title={"Agent List"}
            data={agentList}
            columns={columns}
            options={{
              filterType: "checkbox",
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default ManageAgent;
